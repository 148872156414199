<template>
<div>
  <v-row class="">
    <input
      ref="input"
      type="file"
      class="d-none"
      :accept="tipos.join(',')"
      @change="buscar($event)"
    >
    <v-col cols="12" class="d-flex-column justify-center py-1" >
      <div>
        <v-btn
        @dragover.prevent="hover = true"
        @dragleave.prevent="hover = false"
        @drop.prevent="drop"
        @click="$refs.input.click()"
        :color=color
        title="Arraste o haga click aquí"
      >
        <v-icon small class="mr-2">
          fas fa-folder{{ hover ? '-open' : '' }}
        </v-icon>
        {{ $vuetify.breakpoint.mdAndDown ? 'Archivo' : 'Subir archivo' }}
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn small icon color="info" v-on="on">
            <v-icon small> fas fa-info-circle </v-icon>
          </v-btn>
        </template>
        <span>No incluir encabezado de fila. Tipos admitidos: <strong>{{ tipos.join(', ') }}</strong></span>
      </v-tooltip>
      </div>
      <div v-text="nombre"></div>
    </v-col>
  </v-row>
</div>
</template>

<script>
/*
  COMPONENTE PENSADO PARA SUBIR 1(ADMITE MÁS) EXCEL O TXT Y LEER UNA COLUMNA QUE CONTENGA CÓDIGOS 
  EMITE AL PADRE PARA TRABAJARLOS (ARRAY DE CODIGOS: por ejemplo "codigos de articulos")
 */
import * as XLSX from 'xlsx';
import { tipos_archivos } from '../../util/utils';

export default {
  name: 'SubirArchivos',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }  
  },
  computed:{
    tipos:{
      get(){
        return this.datos.tipos
      }
    },
    cantidad:{
      get(){
        return this.datos.cantidad
      },
    },
    color:{
      get(){
        return this.datos.color ? this.datos.color : 'primary'
      },
    }
  },
  data(){
    return{
      hover:false,
      data: null,
      nombre: '',
      codigosArchivo:[],
    }
  },
  created(){
  },
  methods:{
    validar (files) {
      // obtiene el archivo (en este caso solo puede subir un nro de "cantidad" de archivos)
      const file = files[this.cantidad - 1]
      const tipo = file.type
      this.nombre = file.name

      // busca en el array de tipos_archivos el tipo de archivo del archivo que subio
      const tipo_archivo = tipos_archivos.find(a => a.mime == tipo)

      // verifica que exista el tipo de archivo en el array
      if (tipo_archivo) {
        // verifica que pertenezca por lo menos a un tipo de extension existente en el array de tipos
        if (this.tipos.find(a => a == tipo_archivo.extension)) {
          // el archivo que subio es correcto
          const reader = new FileReader()
          reader.onload = (e) => {
            const bytes = e.target.result
            if (bytes) {
              if(tipo_archivo.extension == '.xlsx'){
                const data = new Uint8Array(bytes);
                const workbook = XLSX.read(data, { type: 'array' });
                // Suponiendo que se quiere leer la primera hoja
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                // Leer la columna A
                const columnA = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                // Extraer la columna deseada (aquí la columna 0 corresponde a A)
                this.codigosArchivo = columnA.map(row => row[0]).filter(Boolean);
              }
              if(tipo_archivo.extension == '.txt'){
                this.data = bytes
                let codigos = this.data.split("\n");
                codigos.forEach(e => {
                  if(e.length > 0){
                    /*this.serie = e.trim();
                    this.agregarSerie();*/
                    this.codigosArchivo.push(e.trim());
                  }
                });
              }
              this.$emit('accion',this.codigosArchivo);
            } else {
              this.$store.dispatch('show_snackbar', {
                text: 'Archivo sin datos',
                color: 'warning'
              })
              this.$emit('accion',[]);
            }
          }
          if(tipo_archivo.extension == '.xlsx'){
            reader.readAsArrayBuffer(file);
          }
          if(tipo_archivo.extension == '.txt') reader.readAsText(file)
          
        } else {
          // subio un archivo con una extension no valida
          this.$store.dispatch('show_snackbar', {
            text: 'Tipo de archivo no válido',
            color: 'error'
          })
          this.$emit('accion',[]);
        }
      } else {
        // subio un tipo de archivo que no esta registrado en el array tipos_archivos
        this.$store.dispatch('show_snackbar', {
          text: 'Tipo de archivo no válido para el sistema',
          color: 'error'
        })
        this.$emit('accion',[]);
      }
      
      // limpia el input de archivos
      this.$refs.input.value = ''
    },
    drop (event) {
      this.hover = false
      const files = event.dataTransfer.files
      if (files.length > 1) {
        this.$refs.input.value = ''
        this.$store.dispatch('show_snackbar', {
          text: 'Solo puede subir un archivo',
          color: 'error'
        })
        return
      }
      this.validar(files)
    },
    buscar(){
      const files = event.target.files
      if (files) this.validar(files)
    },
  },
  components:{
  
  },
  watch:{
   
  }
}
</script>